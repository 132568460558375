import React, { FC } from "react";
import { OfferTemplate } from "@templates/OfferTemplate";
import { graphql } from "gatsby";
import { ChildImageSharp } from "@interfaces/childImageSharp";
import { UniversalSection } from "@components/molecules/UniversalSection";
import { useTranslation } from "gatsby-plugin-react-i18next";
import WhatWeCanDoMore from "@components/organisms/WhatWeCanDoMore";
import { Routes } from "@routes";

const PropertyCard: FC<Props> = ({ data }) => {
  const { t } = useTranslation("offer");
  const { t: ct } = useTranslation("common");

  return (
    <OfferTemplate
      title={ct("title-offer-property-card")}
      description={ct("description-offer-property-card")}
      heroTitle={t("property-card.hero.title")}
      heroDescription={t("property-card.hero.description", {
        returnObjects: true,
      })}
      heroImage={data.heroImage.childImageSharp.gatsbyImageData}
    >
      <UniversalSection
        description={t("property-card.sections.0.description", {
          returnObjects: true,
        })}
        title={t("property-card.sections.0.title")}
        images={[data.firstSectionImage.childImageSharp.gatsbyImageData]}
        fromRight
        btnText={t("see-realization")}
        btnTo={`${Routes.RealisedProjects}palacowa-park-1/`}
      />
      <UniversalSection
        description={t("property-card.sections.1.description", {
          returnObjects: true,
        })}
        title={t("property-card.sections.1.title")}
        images={[data.secondSectionImage.childImageSharp.gatsbyImageData]}
        btnText={t("see-realization")}
        btnTo={`${Routes.RealisedProjects}palacowa-park-1/`}
      />
      <UniversalSection
        description={t("property-card.sections.2.description", {
          returnObjects: true,
        })}
        title={t("property-card.sections.2.title")}
        images={[data.thirdSectionImage.childImageSharp.gatsbyImageData]}
        fromRight
        btnText={t("see-realization")}
        btnTo={`${Routes.RealisedProjects}eko-wkra-strzegowo-1/`}
      />
      <UniversalSection
        description={t("property-card.sections.3.description", {
          returnObjects: true,
        })}
        title={t("property-card.sections.3.title")}
        images={data.fourthSectionGallery.floorPlans3D.map(
          (image) => image.localFile.childImageSharp.gatsbyImageData
        )}
        dotsReverseColor
      />

      <WhatWeCanDoMore exclude={6} />
    </OfferTemplate>
  );
};

interface Props {
  data: {
    heroImage: ChildImageSharp;
    firstSectionImage: ChildImageSharp;
    secondSectionImage: ChildImageSharp;
    thirdSectionImage: ChildImageSharp;
    fourthSectionGallery: {
      floorPlans3D: {
        localFile: ChildImageSharp;
      }[];
    };
  };
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    heroImage: file(name: { eq: "printable-marketing-materials_hero" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED)
      }
    }

    firstSectionImage: file(name: { eq: "printable-marketing-materials_0" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED)
      }
    }

    secondSectionImage: file(name: { eq: "printable-marketing-materials_1" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED)
      }
    }

    thirdSectionImage: file(name: { eq: "printable-marketing-materials_2" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED)
      }
    }

    fourthSectionGallery: strapiVisualization {
      floorPlans3D {
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 80, placeholder: BLURRED)
          }
        }
      }
    }
  }
`;

export default PropertyCard;
